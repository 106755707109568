/* Custom CSS */
.equal-width-buttons {
    flex: 1; /* Equal flex grow for all buttons */
}

/* Base layer to gray out the entire page */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Middle layer with chat container */
.home {
    background-color: white;
    width: 80%;
    max-height: 90%; /* Set the maximum height to 90% */
    padding: 10% 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 750px; /* Max width for two iPhones */
    width: 80%; /* Responsive width */
    max-height: 90vh; /* Adjust height to not exceed the viewport */
    overflow-y: auto; /* Enable scroll if content is taller */
}


/* Top layer for unauthenticated message and buttons */
.home.authenticated {
    background-color: transparent; /* Transparent background when authenticated */
}

/* Add a class to elements that should be grayed out when the user is NOT authenticated */
.gray-out {
    opacity: 0.5; /* Reduce opacity to gray out the content */
    pointer-events: none; /* Disable pointer events to prevent interaction */
}


.top-right-button {
    position: absolute;
    background-color: aliceblue;
    top: 100px;
    right: 20px;
    z-index: 1000;
}

/* Add this CSS class for the subtle button */
.subtle-button {
    background-color: transparent; /* Remove the background color */
    border: 2px solid #000; /* Add a black border */
    color: #000; /* Set the text color to black */
}

.custom-alert {
    background-color: lightblue; /* Light blue background color */
    padding: 20px; /* Increase padding to make it bigger */
    border-radius: 10px; /* Add some rounded corners */
  }
  